import Slider from '../Slider/Slider';
import TestimonialsData from './testimonials.json';
import './Testimonials.css';

function Testimonial ({ message, author, company}) {
  return (
    <div>
      <div className="testimonial-message">{message}</div>
      <div className="testimonial-author">{author}</div>
      <div className="testimonial-company">{company}</div>
    </div>
  )
}

const Testimonials = () => {

  const data = TestimonialsData.map((data, index) => {
    return <Testimonial key={index} message={data.message} author={data.author} company={data.company} />
  });

  return (
    <div className="testimonials">
      <Slider dots data={data} />
    </div>
  )
}

export default Testimonials;
