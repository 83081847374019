import {
  AovBoost,
  Cryptologic,
  Cuemby,
  Doc24,
  Facturante,
  FreightFlow,
  Gueno,
  IntelliNext,
  iPlayMe2,
  Mobbex,
  Orbit,
  SailingProtocol,
  Settle,
  Storeganise,
  Suga,
  Swl,
  Tts,
  Tether,
  Waia,
  Zarasa
} from '../../assets/clients';

const clients = [
  [
    {
      svg: FreightFlow,
      name: 'FreightFlow'
    },
    {
      svg: Settle,
      name: 'Settle'
    },
    {
      svg: Swl,
      name: 'SoftwareLeague'
    },
    {
      svg: Cuemby,
      name: 'Cuemby'
    },
    {
      svg: Storeganise,
      name: 'Storeganise'
    },
    {
      svg: Orbit,
      name: 'Orbit'
    },
    {
      svg: Waia,
      name: 'Waia'
    },
    {
      svg: Facturante,
      name: 'Facturante'
    },
    {
      svg: Gueno,
      name: 'Gueno'
    },
    {
      svg: Cryptologic,
      name: 'Cryptologic'
    },
    {
      svg: Tether,
      name: 'Tether'
    },
    {
      svg: Zarasa,
      name: 'Zarasa'
    },
    {
      svg: Doc24,
      name: 'Doc24'
    },
    {
      svg: AovBoost,
      name: 'AOVBoost'
    },
    {
      svg: Mobbex,
      name: 'Mobbex'
    },
    {
      svg: iPlayMe2,
      name: 'iPlayMe2',
    },
    {
      svg: Tts,
      name: 'TheTappingSolution'
    },
    {
      svg: IntelliNext,
      name: 'IntelliNext'
    },
  ],
  [
    {
      svg: SailingProtocol,
      name: 'SailingProtocol'
    },
    {
      svg: Tether,
      name: 'Tether'
    },
    {
      svg: Zarasa,
      name: 'Zarasa'
    },
    {
      svg: Gueno,
      name: 'Gueno'
    },
    {
      svg: Cryptologic,
      name: 'Cryptologic'
    },
    {
      svg: Settle,
      name: 'Settle'
    },
    {
      svg: iPlayMe2,
      name: 'iPlayMe2',
    },
    {
      svg: FreightFlow,
      name: 'FreightFlow'
    },
    {
      svg: AovBoost,
      name: 'AOVBoost'
    },
    {
      svg: IntelliNext,
      name: 'IntelliNext'
    },
    {
      svg: Cuemby,
      name: 'Cuemby'
    },
    {
      svg: Storeganise,
      name: 'Storeganise'
    },
    {
      svg: Tts,
      name: 'TheTappingSolution'
    },
    {
      svg: Doc24,
      name: 'Doc24'
    },
    {
      svg: Orbit,
      name: 'Orbit'
    },
    {
      svg: Waia,
      name: 'Waia'
    },
    {
      svg: Facturante,
      name: 'Facturante'
    },
    {
      svg: Suga,
      name: 'Suga'
    },
  ],
  [
    {
      svg: Orbit,
      name: 'Orbit'
    },
    {
      svg: IntelliNext,
      name: 'IntelliNext'
    },
    {
      svg: Waia,
      name: 'Waia'
    },
    {
      svg: Facturante,
      name: 'Facturante'
    },
    {
      svg: AovBoost,
      name: 'AOVBoost'
    },
    {
      svg: Zarasa,
      name: 'Zarasa'
    },
    {
      svg: Doc24,
      name: 'Doc24'
    },
    {
      svg: SailingProtocol,
      name: 'SailingProtocol'
    },
    {
      svg: Tts,
      name: 'TheTappingSolution'
    },
    {
      svg: FreightFlow,
      name: 'FreightFlow'
    },
    {
      svg: Settle,
      name: 'Settle'
    },
    {
      svg: Cuemby,
      name: 'Cuemby'
    },
    {
      svg: Swl,
      name: 'SoftwareLeague'
    },
    {
      svg: Storeganise,
      name: 'Storeganise'
    },
    {
      svg: Gueno,
      name: 'Gueno'
    },
    {
      svg: Cryptologic,
      name: 'Cryptologic'
    },
    {
      svg: iPlayMe2,
      name: 'iPlayMe2',
    },
    {
      svg: Tether,
      name: 'Tether'
    },
  ]
]

export default clients;
