import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { Button, Clients, Testimonials } from '../../components';
import LogoSpinner from '../../assets/logo-spinner.svg';
import { registerGAEvent } from '../../lib/gtag';

const Contact = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = t('form_valid_name_required');
    }
  
    if (!values.email) {
      errors.email = t('form_valid_email_required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t('form_valid_email_invalid');
    }
  
    if (!values.message || values.message.length < 20) {
      errors.message = t('form_valid_message_required');
    }
  
    return errors;
  };

  const handleSubmit = async (values) => {
    setSuccess(null);

    if (!loading && !success) {
      setLoading(true);

      try {
        const contactSent = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        })

        const response = await contactSent.json();

        if(response.code === 200) {
          registerGAEvent('event', 'form_submit', {
            event_category: 'form_submit',
            event_label: 'contact_form_sent'
          });
          setSuccess(true);
          formik.handleReset();
        } else {
          registerGAEvent('event', 'form_submit_fail', {
            event_category: 'form_submit',
            event_label: 'contact_form_failed'
          });
          setSuccess(false);
        }

        setLoading(false);

      } catch (e) {
        console.error(e);

        registerGAEvent('event', 'form_submit_fail', {
          event_category: 'form_submit',
          event_label: 'contact_form_network_failed'
        });
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    onSubmit: values => handleSubmit(values),
    validate,
  });

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    }
  }, [success])

  return (
    <>
      <Helmet>
        <meta name="description" content={t('contact_description')} />
        <title>{t('contact_title')}</title>
      </Helmet>

      <div className="section">
        <div className="title">
          {t('contact_hero_1')} <span className="title highlight">{t('contact_hero_2')}</span>
          <br />
          {t('contact_hero_3')}
        </div>
      </div>

      <form className="form" onSubmit={formik.handleSubmit} name="contact_form">

        <div className="input-container">
          <input
            className="input"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t('contact_form_name')}
            type="text"
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? <div className="input-error">{formik.errors.name}</div> : null}
        </div>

        <div className="input-container">
          <input
            className="input"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t('contact_form_email')}
            type="text"
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? <div className="input-error">{formik.errors.email}</div> : null}
        </div>

        <div className="input-container">
          <textarea
            className="input"
            name="message"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t('contact_form_msg')}
            rows="10"
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? <div className="input-error">{formik.errors.message}</div> : null}
        </div>

        {
          success === false
            ? <div className="error">{t('form_error')}</div>
            : null
        }

        <Button
          className={clsx(
            loading ? 'loading' : '',
            !formik.isValid || success === false ? 'error' : ''
          )}
          onClick={() => {
            registerGAEvent('event', 'click', {
              event_category: 'button_click',
              event_label: 'contact_send_button'
            });
          }}
          type="submit"
        >
          {
            loading
              ? <img className="spinner" src={LogoSpinner} alt="loading" width={23} />
              : success
                ? t('form_thank_you')
                : t('contact_form_button_send')
          }
        </Button>
      </form>

      <div className="section">
        <Clients />
      </div>

      <Testimonials />
    </>
  )
}

export default Contact;