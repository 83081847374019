import { Outlet } from "react-router-dom";
import { Footer, Header } from '../../components';
import './Root.css';

function App() {

  return (
    <>

      <Header />

      <div className="app">
        <Outlet />

        <Footer />
      </div>

    </>
  );
}

export default App;
