import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Button, Clients, Testimonials } from '../../components';
import { registerGAEvent } from '../../lib/gtag';

const Hire = () => {
  const { t } = useTranslation();const
  
  handleBookClick = () => {
    registerGAEvent('event', 'click', {
      event_category: 'button_click',
      event_label: 'hire_book_button'
    });
    registerGAEvent('event', 'convertion_event_book_appointment', {
      event_category: 'button_click',
      event_label: 'hire_book_button'
    });
    window.open('https://calendar.app.google/iqqrdDYTqt4Msbeu6', '_blank')
  }

  return (
    <>
      <Helmet>
        <meta name="description" content={t('hire_description')} />
        <title>{t('hire_title')}</title>
      </Helmet>

      <div className="section">
        <div className="title">
          {t('hire_hero_1')} <span className="title highlight">1:1</span>
          <br />
          {t('hire_hero_2')}
        </div>
        <div className="buttons">
          <Button onClick={handleBookClick}>{t('hire_button_book')}</Button>
        </div>
      </div>

      <div className="section">
        <Clients />
      </div>

      <Testimonials />
    </>
  )
}

export default Hire;
