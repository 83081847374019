import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { Button, Clients } from '../../components';
import LogoSpinner from '../../assets/logo-spinner.svg';
import { registerGAEvent } from '../../lib/gtag';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import './Apply.css';

const Apply = () => {
  const { t } = useTranslation();

  const [jobs, setJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/openings`);
        const data = await response.json();

        setJobs(data.data || []);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (job) => {
    if (jobSelected === null || jobSelected.id !== job.id) {
      setJobSelected(job);
    } else {
      setJobSelected(null);
    }
  }

  const cleanUpHTML = (html) => {
    // Replace </p> with double <br/>
    let text = html.replace(/<\/p>|<\/li>/g, '<br/>');

    // Remove all HTML tags except <br/>
    text = text.replace(/<(?!br\s*\/?)[^>]+>/g, '');

    // Replace &nbsp; with a space
    text = text.replace(/&nbsp;/g, ' ');

    return text;
  }

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = t('form_valid_name_required');
    }
  
    if (!values.email) {
      errors.email = t('form_valid_email_required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t('form_valid_email_invalid');
    }
  
    if (!values.phone) {
      errors.phone = t('form_valid_phone_required');
    }
  
    if (!values.country) {
      errors.country = t('form_valid_country_required');
    }
  
    if (!values.state) {
      errors.state = t('form_valid_state_required');
    }
  
    if (!values.city) {
      errors.city = t('form_valid_city_required');
    }
  
    if (!values.linkedin) {
      errors.linkedin = t('form_valid_linkedin_required');
    }
  
    return errors;
  };

  const handleSubmit = async (values) => {
    setSuccess(null);

    if (!loading && !success) {
      setLoading(true);

      try {
        const email = await fetch(`${process.env.REACT_APP_API_URL}/apply`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, job: jobSelected.name }),
        })

        const response = await email.json();

        if(response.code === 200) {
          registerGAEvent('event', 'form_submit', {
            event_category: 'form_submit',
            event_label: 'apply_form_sent'
          });
          setSuccess(true);
          formik.handleReset();
        } else {
          registerGAEvent('event', 'form_submit_fail', {
            event_category: 'form_submit',
            event_label: 'apply_form_failed'
          });
          setSuccess(false);
        }

        setLoading(false);

      } catch (e) {
        console.error(e);

        registerGAEvent('event', 'form_submit_fail', {
          event_category: 'form_submit',
          event_label: 'apply_form_network_failed'
        });
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      country: '',
      state: '',
      city: '',
      linkedin: ''
    },
    onSubmit: values => handleSubmit(values),
    validate,
  });

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    }
  }, [success])

  return (
    <>
      <Helmet>
        <meta name="description" content={t('apply_description')} />
        <title>{t('apply_title')}</title>
      </Helmet>

      <div className="section">
        <div className="title">
          {t('apply_hero_1')} <span className="title highlight">{t('apply_hero_2')}</span>
          <br />
          {t('apply_hero_3')}
        </div>
      </div>

      <div className="section">
        <div className="instructions">{t('apply_instructions')}</div>

        {
          jobs.length > 0
            ? jobs.map((job, index) => {
              return (
                <div className="list" key={index} onClick={() => handleJobClick(job)}>
                  <div className="item">
                    <div className="title bold">
                      {job.name}
                    </div>
                    <div className="details">
                      {job.modality} - {job.location} - {job.seniority} - {job.type}
                    </div>
                  </div>
                </div>
              )
            })
            : t('apply_no_openings')
        }

        {
          jobSelected &&
            <Modal
              center
              classNames={{
                // overlay: 'customOverlay',
                modal: 'modal',
              }}
              onClose={() => setJobSelected(null)}
              open={jobSelected}
            >
              <div className="job">
                <div className="title">
                  {jobSelected.name}
                </div>
                <div className="details">
                  {jobSelected.modality} - {jobSelected.location} - {jobSelected.seniority} - {jobSelected.type}
                </div>
                <div className="description" dangerouslySetInnerHTML={{__html: cleanUpHTML(jobSelected.description)}}></div>
              </div>

              <form className="form light-mode" onSubmit={formik.handleSubmit} name="apply_form">

                <div className="input-container">
                  <input
                    className="input"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_name')}
                    type="text"
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? <div className="input-error">{formik.errors.name}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_email')}
                    type="text"
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? <div className="input-error">{formik.errors.email}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="phone"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_phone')}
                    type="text"
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? <div className="input-error">{formik.errors.phone}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="country"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_country')}
                    type="text"
                    value={formik.values.country}
                  />
                  {formik.touched.country && formik.errors.country ? <div className="input-error">{formik.errors.country}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="state"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_state')}
                    type="text"
                    value={formik.values.state}
                  />
                  {formik.touched.state && formik.errors.state ? <div className="input-error">{formik.errors.state}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="city"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_city')}
                    type="text"
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? <div className="input-error">{formik.errors.city}</div> : null}
                </div>

                <div className="input-container">
                  <input
                    className="input"
                    name="linkedin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder={t('apply_form_linkedin')}
                    type="text"
                    value={formik.values.linkedin}
                  />
                  {formik.touched.linkedin && formik.errors.linkedin ? <div className="input-error">{formik.errors.linkedin}</div> : null}
                </div>

                {
                  success === false
                    ? <div className="error">{t('form_error')}</div>
                    : null
                }

                {
                  jobs.length > 0 && jobSelected === null
                    ? <div className="error">{t('form_missing_opening')}</div>
                    : null
                }

                <Button
                  className={clsx(
                    loading ? 'loading' : '',
                    !formik.isValid || success === false ? 'error' : ''
                  )}
                  disabled={jobs.length > 0 && jobSelected === null}
                  onClick={() => {
                    registerGAEvent('event', 'click', {
                      event_category: 'button_click',
                      event_label: 'apply_send_button'
                    });
                  }}
                  type="submit"
                >
                  {
                    loading
                      ? <img className="spinner" src={LogoSpinner} alt="loading" width={23} />
                      : success
                        ? t('form_thank_you')
                        : t('apply_form_button_apply')
                  }
                </Button>
              </form>
            </Modal>
        }
      </div>

      <div className="section">
        <Clients />
      </div>
    </>
  )
}

export default Apply;
