
import React from 'react';
import logo from '../../assets/logo-bco.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} alt="logo" width={100} />
      <div>
        hello@wearekadre.com
        <br />
        7901 4TH ST N # 17922
        <br />
        St. Petersburg Florida 33702
        <br />
        U.S.A
      </div>
      <div className="social-media">
        <a href='https://linkedin.com/company/wearekadre' target='_blank' rel="noreferrer">
          <img src={linkedin} alt="linkedin" />
        </a>
        <a href='https://instagram.com/wearekadre' target='_blank' rel="noreferrer">
          <img src={instagram} alt="instagram" />
        </a>
      </div>
      <span className="light small">We Are Kadre LLC © Copyright 2024. All Rights Reserved.</span>
    </div>
  );
};

export default Footer;
