import Slider from '../Slider/Slider';
import ClientsData from './ClientsData';
import './Clients.css';

const Clients = () => {

  return (
    <>
      {
        ClientsData.map((data, index) => {
          return (
            <div className="clients" key={index}>
              <Slider dataType="image" data={data} />
            </div>
          )
        })
      }
    </>
  )
}

export default Clients;
