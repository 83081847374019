
import React from 'react';
import clsx from 'clsx';
import './Button.css';

const Button = ({
  children,
  className,
  onClick = () => {},
  variant = 'primary',
  ...props
}) => {

  const variants = {
    primary: 'primary',
    secondary: 'secondary',
  }

  return (
    <button
      className={clsx(
        'button',
        variants[variant],
        className
      )}
      onClick={onClick}
      {...props}
    >
      { children }
    </button>
  );
};

export default Button;
