export const registerGAEvent = (event, action, data) => {

  if (window.gtag) {
    window.gtag(event, action, data);

    console.log('-------------------')
    console.log('GA Event')
    console.log('Event', event)
    console.log('Action', action)
    console.log('Data', data)
    console.log('-------------------')
  } else {
    console.log('-------------------')
    console.warn('GA Event NOT TRACKED!', 'gtag not found!')
    console.log('Event', event)
    console.log('Action', action)
    console.log('Data', data)
    console.log('-------------------')
  }
}