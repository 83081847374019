import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import i18n from 'i18next';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import close from '../../assets/menu-close.svg';
import menu from '../../assets/menu-vertical.svg';
import arg from '../../assets/arg.svg';
import eeuu from '../../assets/eeuu.svg';
import './Nav.css';

const Nav = () => {
  const [open, setOpen] = useState(false);
  const [inStyle, setInStyle] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setInStyle(true);
      }, 1);
    }
  }, [open]);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setInStyle(inStyle ? false : inStyle);
    setOpen(!open);
  }

  const handleCloseClick = (event) => {
    event.stopPropagation();
    setInStyle(false);
    setTimeout(() => {
      handleMenuClick(event);
    }, 250);
  }

  const handleLangClick = (lang) => {
    i18n.changeLanguage(lang);
  }
  
  return (
    open
      ? <div className={clsx('nav-open', inStyle ? 'visible' : '')} onClick={handleMenuClick}>
          <img src={close} className="menu" alt="close" onClick={handleCloseClick} width={32} />
          <NavLink
            to="/"
            className={({ isActive}) => clsx("bold", isActive ? 'highlight' : '')}
            onClick={handleMenuClick}>
              {t('nav_home')}
          </NavLink>
          <NavLink
            to="/hire"
            className={({ isActive}) => clsx("bold", isActive ? 'highlight' : '')}
            onClick={handleMenuClick}>
              {t('nav_hire')}
          </NavLink>
          <NavLink
            to="/apply"
            className={({ isActive}) => clsx("bold", isActive ? 'highlight' : '')}
            onClick={handleMenuClick}>
              {t('nav_apply')}
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive}) => clsx("bold", isActive ? 'highlight' : '')}
            onClick={handleMenuClick}>
              {t('nav_contact')}
          </NavLink>
          <div className="lang">
            <img className={clsx('flag', i18n.language === 'es' ? 'selected' : '')} src={arg} alt="arg" onClick={() => handleLangClick('es')} width={42} />
            <img className={clsx('flag', i18n.language === 'en' ? 'selected' : '')} src={eeuu} alt="eeuu" onClick={() => handleLangClick('en')} width={42} />
          </div>
        </div>
      : <img src={menu} className="menu" alt="menu" onClick={handleMenuClick} width={32} />
  )
}

export default Nav;
