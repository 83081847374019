import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-bco.svg';
import Nav from '../Nav/Nav';
import './Header.css';

const Header = () => {

  const navigate = useNavigate();

  return (
    <header className="header">
      <img src={logo} className="logo" alt="logo" onClick={() => navigate('/')} />
      <Nav />
    </header>
  )
}

export default Header;
