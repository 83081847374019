import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import techstars from '../../assets/techstars.svg';
import linkedin from '../../assets/linkedin.svg';
import hernan from '../../assets/hernan.png';
import emilia from '../../assets/emilia.png';
import { Button, Clients, Testimonials } from '../../components';
import { registerGAEvent } from '../../lib/gtag';
import './Home.css';

const Home = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleHireClick = () => {
    registerGAEvent('event', 'click', {
      event_category: 'button_click',
      event_label: 'home_hire_button'
    });
    navigate('/hire');
  }

  const handleApplyClick = () => {
    registerGAEvent('event', 'click', {
      event_category: 'button_click',
      event_label: 'home_apply_button'
    });
    navigate('/apply');
  }

  return (
    <div>
      <Helmet>
        <meta name="description" content={t('home_description')} />
        <title>{t('home_title')}</title>
      </Helmet>

      <div className="section">
        <div className="title">
          <span className="title highlight">{t('home_hero_1')}</span> {t('home_hero_2')}
        </div>
        <div className="buttons">
          <Button onClick={handleHireClick}>{t('home_button_hire')}</Button>
          <Button onClick={handleApplyClick} variant="secondary">{t('home_button_apply')}</Button>
        </div>
      </div>

      <div className="section">
        <Clients />
      </div>

      <Testimonials />

      <div className="section">
        <div className="title">
        {t('home_founders_hero')}
        </div>
        <div className="founders">
          <div className="founder">
            <img src={hernan} alt="hernan" className="founder-img" />
            <span className="bold">Hernán Farruggia</span>
            <span>Co-Founder & Business Development</span>
            <div className="founder-social-media">
              <img src={linkedin} alt="linkedin" />
              <a
                href="https://linkedin.com/in/hernanfarruggia"
                onClick={() => {
                  registerGAEvent('event', 'click', {
                    event_category: 'link_click',
                    event_label: 'home_linkedin_hernan'
                  });
                }}
                rel="noreferrer"
                target="_blank"
              >
                /hernanfarruggia
              </a>
            </div>
          </div>

          <div className="founder">
            <img src={emilia} alt="emilia" className="founder-img" />
            <span className="bold">Emilia Vicini</span>
            <span>Co-Founder & HR Manager</span>
            <div className="founder-social-media">
              <img src={linkedin} alt="linkedin" />
              <a 
                href="https://linkedin.com/in/emilia-vicini"
                onClick={() => {
                  registerGAEvent('event', 'click', {
                    event_category: 'link_click',
                    event_label: 'home_linkedin_emilia'
                  });
                }}
                rel="noreferrer"
                target="_blank"
              >
                /emilia-vicini
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
