
import SlickSlider from "react-slick";
import './slick.css';
import './slick-theme.css';

const Slider = ({
  data,
  dataType = 'content',
  dots = false,
}) => {

  const screenMaxSize = {
    phone: 768,
    tablet: 1024
  }

  const isMobile = window.innerWidth < screenMaxSize.phone;
  const isTablet = !isMobile && window.innerWidth < screenMaxSize.tablet;

  const slidesToShow = isMobile
    ? 2
    : isTablet
      ? 3
      : 6; // Desktop

  const settings = {
    adaptativeHeight: true,
    arrows: false,
    autoplay: dataType === 'image',
    autoplaySpeed: 2500,
    cssEase: "linear",
    dots,
    infinite: true,
    pauseOnHover: false,
    slidesToShow: dataType === 'image' ? slidesToShow : 1,
    slidesToScroll: 1,
    speed: 250,
  }

  return (
    <SlickSlider {...settings}>
      {
        dataType === 'image'
          ? data.map((logo, index) => (
            <img key={index} src={logo.svg} className="logo" alt={logo.name} />
          ))
          : data.map((content, index) => (
            <div key={index}>
              {content}
            </div>
          ))
      }
    </SlickSlider>
  );
};

export default Slider;
